import {useMutation} from '@apollo/client';
import {Mutation, SouhlasIdentifikaceZadostiInput, SouhlasMutationCheckAndCreateZadostArgs, SouhlasSubjekt} from '@eon.cz/apollo13-graphql-vyjadrovaci-linka';
import {yupResolver} from '@hookform/resolvers/yup';
import {InputOutlined} from '@mui/icons-material';
import {Grid, Link, Typography} from '@mui/material';
import {create} from 'lodash';
import {useRouter} from 'next/router';
import {FC, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {FormattedMessage, useIntl} from 'react-intl';
import {useCommonAction} from '../../../common/action/CommonAction';
import {LoadingDialog} from '../../../common/components/dialogs/LoadingDialog';
import {FormInput} from '../../../common/components/forms/FormInput';
import {ButtonBase} from '../../../common/components/helperComponents/ButtonBase';
import {onSubmitFailFocusFieldWithError} from '../../../common/components/helperComponents/ErrorFocus';
import {PaperBlock} from '../../../common/components/layout/PaperBlock';
import {PageRoute} from '../../../common/constants';
import {Span} from '../../../common/styledComponents/Span';
import {EnvironmentUtils} from '../../../common/utils/EnvironmentUtils';
import {validationSchemaStepOne} from '../../../common/utils/Validations';
import {StepOneMutations} from '../graphql/StepOneMutations';
import {StepOneNapoveda} from './StepOneNapoveda';

// Komponenta pro zobrazení vstupní obrazovky zadání emailu,eanu a obce v případě změny
export const StepOne: FC = () => {
    // local state
    const [openNapoveda, setOpenNapoveda] = useState(false);

    // methods
    const {formatMessage} = useIntl();
    const {push} = useRouter();
    const {setActiveStep, setFormData, setSuccessStep} = useCommonAction();
    const [checkAndCreateZadost, {loading}] = useMutation<Mutation, SouhlasMutationCheckAndCreateZadostArgs>(StepOneMutations.gql.stepOneSendData, {
        onCompleted: (res) => {
            if (res.souhlas?.checkAndCreateZadost) {
                const zadatelAddressType = res.souhlas.checkAndCreateZadost.zadatel?.isNewAddressType;
                const investorAddressType = res.souhlas.checkAndCreateZadost.investor?.isNewAddressType;
                setActiveStep(1);
                setSuccessStep(0);
                setFormData({
                    id: res.souhlas.checkAndCreateZadost.id,
                    stepTwo: {
                        zadatelJeInvestor: !!res.souhlas.checkAndCreateZadost.zadatelJeInvestor,
                        zadatel: {
                            ...res.souhlas.checkAndCreateZadost.zadatel,
                            adresa: zadatelAddressType ? res.souhlas.checkAndCreateZadost.zadatel?.adresa : undefined,
                            zahranici: res.souhlas.checkAndCreateZadost.zadatel?.adresa?.stat ? 'ANO' : 'NE',
                        },
                        investor: {
                            ...res.souhlas.checkAndCreateZadost.investor,
                            adresa: investorAddressType ? res.souhlas.checkAndCreateZadost.investor?.adresa : undefined,
                            zahranici: res.souhlas.checkAndCreateZadost.investor?.adresa?.stat ? 'ANO' : 'NE',
                            subjekt: res.souhlas.checkAndCreateZadost.investor?.subjekt ?? SouhlasSubjekt.FYZICKA_OSOBA,
                            telefon: res.souhlas.checkAndCreateZadost.investor?.telefon ?? '+420',
                        },
                    },
                    stepThree: {
                        ...res.souhlas.checkAndCreateZadost.technickaSpecifikace,
                        elektrina: res.souhlas.checkAndCreateZadost.technickaSpecifikace?.elektrina ? 'ELEKTRINA' : undefined,
                        plyn: res.souhlas.checkAndCreateZadost.technickaSpecifikace?.plyn ? 'PLYN' : undefined,
                    },
                });
                push(PageRoute.OSOBNI_UDAJE);
            }
        },
        onError: () => undefined,
    });
    const handleOpenNapoveda = () => setOpenNapoveda((prev) => !prev);
    const onSubmit = (data: SouhlasIdentifikaceZadostiInput) => {
        checkAndCreateZadost({variables: {input: data}});
        // eslint-disable-next-line no-console
        !EnvironmentUtils.isProdPreprod() && console.warn('🚨 -> input: ', data);
    };

    // form
    const methods = useForm<SouhlasIdentifikaceZadostiInput>({
        resolver: yupResolver(validationSchemaStepOne),
        mode: 'onChange',
        defaultValues: {
            cisloStanoviska: '',
            datumPlatnosti: undefined,
            obec: '',
        },
    });
    const {handleSubmit} = methods;

    return (
        <PaperBlock
            titleBlock={<FormattedMessage id="step.one.title" />}
            description={
                <Span sx={{display: 'flex', alignItems: 'center'}}>
                    <Typography variant="body2" color="secondary">
                        <FormattedMessage id="step.one.description" />
                    </Typography>
                </Span>
            }
            titleBlockIcon={<InputOutlined color="primary" fontSize="large" />}
        >
            {loading && <LoadingDialog open />}
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit, onSubmitFailFocusFieldWithError)} noValidate>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={3.5}>
                            <FormInput type="text" label="step.one.cislo" name="cisloStanoviska" tooltip="step.one.cislo.tooltip" autofocus required />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={4.5}>
                            <FormInput
                                type="date"
                                label="step.one.datum"
                                name="datumPlatnosti"
                                tooltip="step.one.datum.tooltip"
                                disablePast
                                required
                                disabled={!create}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4}>
                            <FormInput type="text" label="step.one.obec" name="obec" tooltip="step.one.obec.tooltip" required disabled={!create} />
                        </Grid>
                    </Grid>
                    <Grid container sx={{marginTop: 5}} spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                <FormattedMessage
                                    id="step.one.poznamka"
                                    values={{
                                        napoveda: (
                                            <Link sx={{cursor: 'pointer'}} onClick={handleOpenNapoveda}>
                                                {formatMessage({id: 'napoveda'})}
                                            </Link>
                                        ),
                                    }}
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                    <ButtonBase hideButton />
                </form>
            </FormProvider>
            {openNapoveda && <StepOneNapoveda onClose={handleOpenNapoveda} />}
        </PaperBlock>
    );
};
