import {Dialog, DialogActions, DialogContent} from '@mui/material';
import Image from 'next/image';

import {ActionButton} from '../../../common/components/buttons/ActionButton';
import {DialogTitleWithClose} from '../../../common/components/detail/DialogTitleWithClose';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import vyjadreni from '../../../../public/static/images/Vzor.png';
type Props = {
    readonly onClose: () => void;
};
export const StepOneNapoveda = ({onClose}: Props) => {
    return (
        <Dialog open keepMounted onClose={onClose} fullWidth>
            <DialogTitleWithClose title="step.one.napoveda" onClose={onClose} />

            <DialogContent>
                <Image alt="Napoveda" src={vyjadreni} width={550} height={800} />
            </DialogContent>
            <DialogActions>
                <ActionButton size="small" color="primary" variant="contained" onClick={onClose} caption={'button.zavrit'} />
            </DialogActions>
        </Dialog>
    );
};
