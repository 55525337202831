import {gql} from '@apollo/client';

export const StepOneMutations = {
    gql: {
        stepOneSendData: gql`
            mutation StepOneCreateZadost($input: SouhlasIdentifikaceZadostiInput!) {
                souhlas {
                    checkAndCreateZadost(input: $input) {
                        id
                        investor {
                            isNewAddressType
                            adresa {
                                cisloPopisne
                                cisloOrientacni
                                cisloEvidencni
                                obec
                                psc
                                stat
                                ulice
                            }
                            email
                            ico
                            jmeno
                            nazevFirmy
                            prijmeni
                            subjekt
                            telefon
                            titulPred
                        }
                        technickaSpecifikace {
                            elektrina
                            plyn
                            prilohy {
                                id
                                mime
                                nazev
                                referenceId
                                velikost
                                vytvoreno
                            }
                            udajeOStavbeNazev
                            udajeOStavbePopis
                        }
                        zadatel {
                            isNewAddressType
                            adresa {
                                cisloPopisne
                                cisloOrientacni
                                cisloEvidencni
                                obec
                                psc
                                stat
                                ulice
                            }
                            email
                            ico
                            jmeno
                            nazevFirmy
                            prijmeni
                            subjekt
                            telefon
                            titulPred
                        }
                        zadatelJeInvestor
                    }
                }
            }
        `,
    },
};
