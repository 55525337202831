import {Close as CloseIcon} from '@mui/icons-material';
import {DialogTitle, IconButton, Typography} from '@mui/material';
import {DialogTitleProps} from '@mui/material/DialogTitle';
import {FormattedMessage} from 'react-intl';

type DialogTitleWithCloseProps = DialogTitleProps & {
    /**
     * ID of dialog title message
     */
    readonly title: string;

    /**
     * Dialog title color
     */
    readonly color?: 'primary' | 'secondary';

    /**
     * Disable close button
     */
    readonly disabledClose?: boolean;

    /**
     * Handled called when close is clicked
     */
    readonly onClose: () => void;
};

/**
 * Render dialog title when close button
 */
export const DialogTitleWithClose = ({title, onClose, color, disabledClose, ...props}: DialogTitleWithCloseProps) => {
    return (
        <DialogTitle
            {...props}
            sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                padding: `${theme.spacing() + 1}px ${theme.spacing()}px ${theme.spacing()}px ${theme.spacing(3)}px`,
                ...(color === 'primary' && {
                    backgroundColor: theme.palette.primary.main,
                }),
                ...(color === 'secondary' && {
                    backgroundColor: theme.palette.secondary.main,
                }),
            })}
        >
            <Typography
                sx={(theme) => ({
                    flex: 1,
                    fontSize: 25,
                    marginLeft: `${theme.spacing()}px`,
                    ...(color === 'primary' && {
                        color: theme.palette.primary.contrastText,
                    }),
                    ...(color === 'secondary' && {
                        color: theme.palette.secondary.contrastText,
                    }),
                })}
            >
                <FormattedMessage id={title} />
            </Typography>
            <IconButton
                color="inherit"
                onClick={onClose}
                aria-label="Close"
                sx={(theme) => ({
                    marginLeft: `${theme.spacing()}px`,
                    ...(color === 'primary' && {
                        color: theme.palette.primary.contrastText,
                    }),
                    ...(color === 'secondary' && {
                        color: theme.palette.secondary.contrastText,
                    }),
                })}
                data-testid={`dialog-title-close-button`}
                disabled={disabledClose}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>
    );
};
